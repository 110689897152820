import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PaymentIntegrationAPI from 'api/payment-integration'
import { isCustomerEditBooking } from 'utils/new_booking/common'
import { calculate, updateBookingAction } from 'store/actions/common/bookingActionCreators'
import { paymentMethodSelected } from 'utils/eventTrackOnlinePayment'
import { Utils } from 'utils/Utils'
import isEmpty from 'lodash/isEmpty'
import { updateExtraInfos } from 'store/actions/common/extraInfosCreators'
import { METHOD_CASH, PAYMENT_METHOD_CONVERT_LIST } from 'constants/bookingConstants'

import './PaymentMethod.scss'

const PaymentMethod = ({ setIsWaitingCalculate, isWaitingCalculate }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentCustomer = useSelector((state) => state.currentCustomer)
  const booking = useSelector(state => state.booking)
  const extraInfos = useSelector(state => state.extraInfos)
  const paymentSettings = extraInfos?.paymentSettings
  const { bankTransfer, bookingPaymentDetails, display_payment_service_fee_setting: displayFee } = booking
  const prevCustomerId = useRef(currentCustomer?.id)

  const handleGetPaymentSettingAction = async (params) => {
    const { isLogin, isGetList } = params
    const dataAPI = await PaymentIntegrationAPI.getPaymentSettingAPI(booking.country_code)
  
    if (dataAPI.status !== 200) {
      return
    }
  
    const { data } = dataAPI
    dispatch(updateExtraInfos({ paymentSettings: [...data] }))
  
    const selectedId = booking?.bankTransfer?.id || null
    const defaultItem = data.find(item => item.settings.checkByDefault === true && item.method !== METHOD_CASH) || {}
    const selectedItem = isLogin
      ? data.find(item => item.id === selectedId) || {}
      : isEmpty(booking?.bankTransfer)
        ? defaultItem
        : data.find(item => item.id === selectedId) || defaultItem
    dispatch(updateBookingAction({ bankTransfer: selectedItem }))
    if ((isLogin && !isEmpty(selectedItem)) || isGetList) {
      dispatch(calculate())
    }
  }

  const handleClickPayment = (event, item) => {
    event.stopPropagation()
    setIsWaitingCalculate(true)
    if (item.method === 'card') paymentMethodSelected(PAYMENT_METHOD_CONVERT_LIST[item.method])
    else paymentMethodSelected(PAYMENT_METHOD_CONVERT_LIST[item.method], item?.bankName)
    if (item?.method !== METHOD_CASH) dispatch(updateBookingAction({ bankTransfer: item }))
    else dispatch(updateBookingAction({ bankTransfer: {}, payment_service_fee: 0 }))
    dispatch(calculate(() => setIsWaitingCalculate(false)))
  }

  const replaceTextWithFee = (displayFee, translations) => {
    const serviceTypeTextWithKey = translations?.serviceTypeTextWithKey || ''
    const replaceKey = translations?.replaceKey || ''
    const regex = new RegExp(replaceKey, "g");
    const replacedText = serviceTypeTextWithKey.replace(regex, `<span class="replace-fee">${
      Utils.formatFee(displayFee, extraInfos.country_language, booking.currency)
    }</span>`);
    return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
  }

  const renderBankItem = () => {
    return (
      <div className="bank-item-section">
        {paymentSettings.map((item, index) => {
          let checked = (isEmpty(bankTransfer) && item.method === METHOD_CASH) || bankTransfer?.id === item?.id
          if (isCustomerEditBooking() && bookingPaymentDetails?.payment_method_setting_uuid !== bankTransfer?.id) checked = false
          return (
            <div className={index < paymentSettings.length - 1 ? "bank-item-border-bottom" : "bank-item"} key={index}>
              <label className={`${(isCustomerEditBooking() || isWaitingCalculate) ? 'checkbox-circle-ceb' : 'checkbox-circle'}`}>
                <input
                  type="radio"
                  checked={checked}
                  onClick={(event) => handleClickPayment(event, item)}
                />
                <span className="checkmark" />
              </label>
              <div className="bank-info">
                <div className="bank-name-logo">
                  <div className={`bank-name ${checked ? "selected-bank" : ""}`}>
                    {item.translations.serviceTypeName}
                  </div>
                  <div className="bank-content">
                    {item.translations.serviceTypeDetail}
                  </div>
                  {bankTransfer?.id === item.id && !isWaitingCalculate && (
                    <div className="bank-content">
                      {replaceTextWithFee(displayFee || 0, bankTransfer?.translations)}
                    </div>
                  )}
                </div>
              </div>
              {item.settings?.logoUrl && (
                <div className="bank-logo">
                  <img src={item.settings.logoUrl} alt="bank-logo" />
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    // isGetList support get list if step 2 call failed or cancel to edit
    // Cancel to edit go step 3 and empty paymentSettings if booking?.special_settings?.enable_online_payment
    const isGetList = isEmpty(paymentSettings) && booking?.special_settings?.enable_online_payment
    if (isEmpty(paymentSettings) && booking?.special_settings?.enable_online_payment) {
      handleGetPaymentSettingAction({ isGetList })
    }
  }, [paymentSettings])

  useEffect(() => {
    if (isCustomerEditBooking() && !isEmpty(bookingPaymentDetails)) {
      const matchIndex = paymentSettings?.findIndex(item => item.id === bookingPaymentDetails.payment_method_setting_uuid)
      if (matchIndex !== -1) {
        dispatch(updateBookingAction({ bankTransfer: paymentSettings[matchIndex] }))
      }
    }
  }, [bookingPaymentDetails?.id, paymentSettings])

  useEffect(() => {
    if (!prevCustomerId.current && currentCustomer?.id && booking?.special_settings?.enable_online_payment) {
      handleGetPaymentSettingAction({ isLogin: true })
    }
  }, [currentCustomer?.id])
  
  if (isEmpty(paymentSettings)) return null

  return (
    <div className="select-payment-container">
      <div className="header">
        {t('webapp.label.payment_method')}
      </div>
      {renderBankItem()}
    </div>
  )
}

PaymentMethod.propTypes = {
  setIsWaitingCalculate: PropTypes.func.isRequired,
}

export default PaymentMethod
