import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {}

const waypointOrderSlice = createSlice({
  name: 'waypointOrder',
  initialState,
  reducers: {
    updateWaypointOrder: (state, action: PayloadAction<any>) => action.payload,
  },
  extraReducers: () => {},
})

export const waypointOrderActionsCreator = waypointOrderSlice.actions

export default waypointOrderSlice
