import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { needResetRoundTripDiscount, removeRoundTripDiscount } from 'utils/ez_spread_sheet/locations/bookingLocations'

const initialState: any = {}

const selectedBookingSlice = createSlice({
  name: 'selectedBooking',
  initialState,
  reducers: {
    setSelectedBooking: (state, action: PayloadAction<any>) => {
      return { ...action.payload.booking }
    },
    addNewLocationSelectedBooking: (state, action: PayloadAction<any>) => {
      const currentBooking = { ...state, locations: [...state.locations] }
      const locations = [...currentBooking.locations]
      const locationSize = _.size(locations)
      const newLocation = {
        temp_id: Math.random().toString(36).slice(2),
        order: undefined,
        name: '',
        lat: undefined,
        lng: undefined,
        postcode: '',
        recipient_name: '',
        recipient_phone: '',
        location_note: '',
        need_pod: '',
        pod_note: '',
        need_cod: '',
        cod_note: '',
        round_trip_discount: false,
        cod_invoice_fees: '',
        verify: {
          name: false,
          recipient_name: false,
          recipient_phone: false,
          location_note: true,
        },
        marker: undefined,
        can_toggle_need_pod: action.payload.currentCustomer.can_toggle_pod,
      }
      if (currentBooking.round_trip_discount) {
        const destination = { ...locations[locationSize - 1] }
        locations[locationSize - 1] = newLocation
        locations[locationSize - 1].order = locationSize - 1
        locations[locationSize] = destination
        locations[locationSize].order = locationSize
      } else {
        locations[locationSize] = newLocation
        locations[locationSize].order = locationSize
      }
      currentBooking.locations = locations
      _.assign(currentBooking, { is_optimized: false })
      return currentBooking
    },
    updateLocationSelectedBooking: (state, action: PayloadAction<any>) => {
      const locations = state.locations
      const locationIndex = _.findIndex(state.locations, (locaion:any) => locaion.temp_id === action.payload.location.temp_id)
      locations[locationIndex] = action.payload.location
      if (action.payload.resetOptimized) {
        state.is_optimized = false
      }
    },
    insertRoundTripDiscountSelectedBooking: (state, action: PayloadAction<any>) => {
      const locations = state.locations
      const destination:any = _.last(locations)
      const tempLocation = { ...action.payload.location }
      const maxNumberOfLocation = action.payload.maxNumberOfLocation
      // in case: the destination is empty location -- makes like this due to location maker
      if (
        _.size(locations) === maxNumberOfLocation &&
        _.isUndefined(destination.lng) &&
        _.isUndefined(destination.lat)
      ) {
        _.remove(locations, { temp_id: destination.temp_id })
        locations[_.size(locations)] = tempLocation
        if (state.is_optimized) {
          _.remove(state.locationBeforeOptimize, { temp_id: destination.temp_id })
          state.locationBeforeOptimize.push(tempLocation)
        }
      } else if (destination.lng !== tempLocation.lng || destination.lat !== tempLocation.lat) {
        locations[_.size(locations)] = tempLocation
        if (state.is_optimized) {
          state.locationBeforeOptimize.push(tempLocation)
        }
      }
      _.forEach(locations, (loc, locIndex) => {
        _.assign(loc, { order: locIndex })
      })
      state.locations = locations
      state.round_trip_discount = true
    },
    deleteLocationSelectedBooking: (state, action: PayloadAction<any>) => {
      const currentBooking = { ...state, locations: [...state.locations] }
      const locations = [...currentBooking.locations]
      const destination = _.last(locations)
      const location = { ...action.payload.location }
      const removeRoundTripDiscount = currentBooking.round_trip_discount && destination.temp_id === location.temp_id
      _.remove(locations, { temp_id: location.temp_id })
      _.forEach(locations, (loc, locIndex) => {
        _.assign(loc, { order: locIndex })
      })
      if (removeRoundTripDiscount && currentBooking.is_optimized) {
        _.remove(currentBooking.locationBeforeOptimize, { temp_id: location.temp_id })
      }
      if (removeRoundTripDiscount) {
        _.assign(currentBooking, { round_trip_discount: false, locations })
      } else {
        _.assign(currentBooking, { is_optimized: false, locations })
      }
      return currentBooking
    },
    addExtraRequirementToSelectedBooking: (state, action: PayloadAction<any>) => {
      state.extra_requirements.push(action.payload.extraRequirement)
    },
    deleteExtraRequirementSelectedBooking: (state, action: PayloadAction<any>) => {
      _.remove(state.extraRequirements, { id: action.payload.extraRequirement.id })
    },
    updateCustomReimbursementToSelectedBooking: (state, action: PayloadAction<any>) => {
      const index = state.customReimbursements.findIndex((item: any) => item.id === action.payload.customReimbursement.id)
      if (index !== -1) {
        state.customReimbursements[index] = action.payload.customReimbursement
      }
    },
    sortBookingLocationsOrder: (state, action: PayloadAction<any>) => {
      state.locations = _.sortBy(state.locations, ['order'])
    },
    updateSelectedBooking: (state, action: PayloadAction<any>) => {
      const currentBooking = { ...state, locations: [...state.locations] }
      return _.assign({}, currentBooking, { ...action.payload.attrs })
    },
    removeExtraPerLocation: (state, action: PayloadAction<any>) => {
      const { extra } = action.payload.payload
      _.forEach(state.locations, (location) => {
        _.remove(location.extra_requirement_locations, { id: extra.id })
      })

    },
    updateExtraPerLocation: (state, action: PayloadAction<any>) => {
      const currentBooking = { ...state }
      const { locations } = currentBooking
      const { extra, locationId } = action.payload.payload
      const location = _.find(locations, ({ temp_id: id }) => id === locationId)
      let locationExtras = location.extra_requirement_locations || []

      if (locationExtras.length) {
        const extraIndex = _.findIndex(locationExtras, ({ id }) => id === extra.id)
        if (extraIndex > -1) {
          locationExtras[extraIndex] = { ...extra }
        } else {
          locationExtras = [...locationExtras, extra]
        }
      } else {
        locationExtras = [extra]
      }

      location.extra_requirement_locations = locationExtras

      return {
        ...currentBooking,
      }
    },
    updateRoundTripDiscountSelectedBooking: (state, action: PayloadAction<any>) => {
      const currentBooking = { ...state }
      const newLocation = action.payload.attrs
      const needReset = needResetRoundTripDiscount(currentBooking, newLocation)
      if (newLocation.order === 0 || needReset) {
        const tempBooking = removeRoundTripDiscount(currentBooking, undefined)
        return tempBooking
      }
      return currentBooking
    }
  },
  extraReducers: () => {},
})
export const selectedBookingActionsCreator = selectedBookingSlice.actions

export default selectedBookingSlice
