export const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP'
export const NEED_TO_VALIDATE_STEP = 'NEED_TO_VALIDATE_STEP'
// for Batch
export const UPDATE_BATCH = 'UPDATE_BATCH'
export const INIT_BATCH = 'INIT_BATCH'
export const UPDATE_BOOKING_LOCATION = 'UPDATE_BOOKING_LOCATION'
export const DELETE_BOOKING_LOCATION = 'DELETE_BOOKING_LOCATION'
export const UPDATE_BOOKING_BADGE = 'UPDATE_BOOKING_BADGE'
export const ADD_NEW_LOCATION_SELECTED_BOOKING = 'ADD_NEW_LOCATION_SELECTED_BOOKING'
export const UPDATE_LOCATION_SELECTED_BOOKING = 'UPDATE_LOCATION_SELECTED_BOOKING'
export const UPDATE_ROUND_TRIP_DISCOUNT_SELECTED_BOOKING = 'update-round-trip-discount-selected-booking'
export const DELETE_LOCATION_SELECTED_BOOKING = 'DELETE_LOCATION_SELECTED_BOOKING'
export const RESET_BATCH_BOOKING = 'RESET_BATCH_BOOKING'
export const REVERT_BATCH_BOOKING = 'REVERT_BATCH_BOOKING'
export const UPDATE_BATCH_TEMPLATE = 'UPDATE_BATCH_TEMPLATE'
export const SET_SELECTED_BOOKING = 'SET_SELECTED_BOOKING'
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION'
export const UPDATE_PICKUP_CONTACTS = 'UPDATE_PICKUP_CONTACTS'
export const UPDATE_PICKUP_CONTACTS_ME_FROM_LOCATION = 'UPDATE_PICKUP_CONTACTS_ME_FROM_LOCATION'
export const DELETE_PICKUP_CONTACT = 'DELETE_PICKUP_CONTACT'
export const UPDATE_DRAFT_BATCHES = 'UPDATE_DRAFT_BATCHES'
export const UPDATE_SAVED_BATCHES = 'UPDATE_SAVED_BATCHES'
export const UPDATE_TRANSIT_TIME = 'UPDATE_TRANSIT_TIME'
export const UPDATE_ALL_BOOKINGS_TRANSIT_TIME = 'UPDATE_ALL_BOOKINGS_TRANSIT_TIME'
export const SET_EVENT = 'SET_EVENT'
export const DELETE_PICKUP_CONTACTS_ME_FROM_LOCATION = 'DELETE_PICKUP_CONTACTS_ME_FROM_LOCATION'

// Matrix
export const UPDATE_EXCEL_MATRIX = 'UPDATE_EXCEL_MATRIX'
export const UPDATE_EXCEL_MATRIX_ITEM = 'UPDATE_EXCEL_MATRIX_ITEM'
export const SELECTED_EXCEL_MATRIX_ROW = 'SELECTED_EXCEL_MATRIX_ROW'

export const UPDATE_CURRENT_POPUP_ID = 'UPDATE_CURRENT_POPUP_ID'
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS'
export const UPDATE_PERSONAL_CONTACTS = 'UPDATE_PERSONAL_CONTACTS'
export const UPDATE_NEW_CONTACT = 'UPDATE_NEW_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const ADD_NEW_BUSINESS_CONTACT = 'ADD_NEW_BUSINESS_CONTACT'
export const ADD_NEW_PERSONAL_CONTACT = 'ADD_NEW_PERSONAL_CONTACT'
export const UPDATE_SINGLE_PERSONAL_CONTACT = 'UPDATE_SINGLE_PERSONAL_CONTACT'
export const DELETE_PERSONAL_CONTACT = 'DELETE_PERSONAL_CONTACT'
// for Popup ID
export const SELECT_CONTACT_POPUP = 'batch-select-contact'
export const ADD_CONTACT_POPUP = 'batch-add-contact'
export const LOCATION_ADDRESS_POPUP = 'batch-location-address-popup'
export const LOCATION_NOTE_POPUP = 'batch-location-note-popup'
export const LOCATION_CONTACTS_POPUP = 'batch-location-contacts-popup'
export const LOCATION_POD_COD_POPUP = 'batch-location-pod-cod-popup'
export const BOOKING_ATTACHMENTS_POPUP = 'batch-booking-attachments-popup'
export const BOOKING_SUMMARY_POPUP = 'batch-booking-summary-popup'
export const SORT_BOOKING_LOCATIONS_ORDER = 'SORT_BOOKING_LOCATIONS_ORDER'
export const CONFIRM_POPUP = 'CONFIRM_POPUP'
export const EZ_SAVED_BOOKINGS_POPUP = 'EZ_SAVED_BOOKINGS_POPUP'
export const ADD_FROM_DRAFT_BOOKING_POPUP = 'ADD_FROM_DRAFT_BOOKING_POPUP'
// for step 0
export const BATCHES_PER_PAGE = 4
export const EZ_SAVED_BOOKING_PERPAGE = 5
export const UPDATE_EXTRA_INFO_USE_DATA_AGAIN = 'UPDATE_EXTRA_INFO_USE_DATA_AGAIN'

export const EXTRA_REQUIREMENT_TYPE = {
  EXTRA_REQUIREMENTS_REGULAR: 'extra_requirements_regular',
  EXTRA_REQUIREMENTS_LONG_HAUL: 'extra_requirements_long_haul',
  EXTRA_REQUIREMENTS_FULL_DAY: 'extra_requirements_full_day',
  REGULAR_BY_OPTION_EXTRAS: 'regular_by_option_extras',
  FULL_DAY_BY_OPTION_EXTRAS: 'full_day_by_option_extras',
  LONG_HAUL_BY_OPTION_EXTRAS: 'long_haul_by_option_extras',
}

// for upload (step 1)
export const MIN_NUMBER_OF_ROWS = 2
// including header row
export const MAX_NUMBER_OF_BOOKINGS = 50
export const TAB_DATA_UPDATE = 'TAB_DATA_UPDATE'
export const TAB_DATA_REMOVE_FILE = 'TAB_DATA_REMOVE_FILE'
export const TAB_DATA_REMOVE_SHEET = 'TAB_DATA_REMOVE_SHEET'
export const CURRENT_TAB_UPDATE = 'CURRENT_TAB_UPDATE'
export const MIN_AVAILABLE_LOCATION = 2
// for validate (step 4)
export const PICKUP_TIME_POPUP = 'batch-select-pickuptime'
export const EXTRA_REQUIREMENT_POPUP = 'batch-update-extra-requirement'
export const BOOKING_NAME_POPUP = 'batch-update-booking-name'
export const JOB_ORDER_NUMBER_POPUP = 'batch-job-order-number'
export const SUB_ACCOUNT_TAG_POPUP = 'batch-update-sub-account-tag'
export const APPLY_ALL_FOR_ENTIRE_SUB_ACCOUNT_TAG = 'batch-booking-apply-all-sub-account-tag'
export const VEHICLE_TYPE_POPUP = 'batch-update-vehicle-type'
export const BOOKING_BADGE_POPUP = 'batch-booking-vehicle-type-badge'
export const BOOKING_NOTE_POPUP = 'batch-booking-note-badge'
export const BOOKING_COD_NOTE_POPUP = 'batch-booking-cod-note'
export const BOOKING_POD_NOTE_POPUP = 'batch-booking-pod-note'
export const BOOKING_BY_OPTION_EXTRA_POPUP = 'batch-booking-by-option-popup'
export const APPLY_ALL_FOR_ENTIRE_BATCH_POPUP = 'batch-booking-apply-all'
export const BOOKING_TIME_TYPE_POPUP = 'batch-booking-time-type'
export const BACK_CONFIRM_POPUP = 'back-confirm-popup'
export const SUB_POPUP_WHEN_DATA_IS_CHANGED = 'sub-popup-when-data-is-changed'
export const INSERT_ROUND_TRIP_DISCOUNT_SELECTED_BOOKING = 'insert-round-trip-discount-selected-booking'
export const BOOKING_OTHER_REIMBURSEMENT_POPUP = 'batch-booking-other-reimbursement-popup'
export const REMOVE_EXTRA_REQUIREMENT_PER_LOCATION = 'REMOVE_EXTRA_REQUIREMENT_PER_LOCATION'
export const UPDATE_EXTRA_REQUIREMENT_PER_LOCATION = 'UPDATE_EXTRA_REQUIREMENT_PER_LOCATION'
export const UPDATE_ADDRESS_FOR_LOCATION = 'UPDATE_ADDRESS_FOR_LOCATION'

// for step 6
export const UPDATE_BOOKING_EXTRA_REQUIREMENTS = 'UPDATE_BOOKING_EXTRA_REQUIREMENTS'
export const ADD_EXTRA_REQUIREMENT_TO_SELECTED_BOOKING = 'ADD_EXTRA_REQUIREMENT_TO_SELECTED_BOOKING'
export const DELETE_EXTRA_REQUIREMENT_SELECTED_BOOKING = 'DELETE_EXTRA_REQUIREMENT_SELECTED_BOOKING'
export const UPDATE_CUSTOM_REIMBURSEMENT_TO_SELECTED_BOOKING = 'UPDATE_CUSTOM_REIMBURSEMENT_TO_SELECTED_BOOKING'
export const UPDATE_SELECTED_BATCH = 'UPDATE_SELECTED_BATCH'
export const UPDATE_BATCH_BOOKINGS = 'UPDATE_BATCH_BOOKINGS'
export const ADD_NEW_BOOKINGS = 'ADD_NEW_BOOKINGS'
export const DELETE_SELECTED_BOOKINGS = 'DELETE_SELECTED_BOOKINGS'
export const UPDATE_BATCH_BOOKING_ATTRS = 'UPDATE_BATCH_BOOKING_ATTRS'
export const UPDATE_BATCH_BOOKING_ATTRS_FOR_BATCHES = 'UPDATE_BATCH_BOOKING_ATTRS_FOR_BATCHES'
export const VERIFY_TIME_TYPE = 'verify-time_type'
export const VERIFY_PICKUP_TIME = 'verify-pickup-time'
export const VERIFY_NONE = 'verify-none'
export const VEHICLE_TYPE = 'vehicle_type'
export const PICKUP_DATE_TIME = 'pickup_date_time'
export const MAPPING_NAME = 'name'
export const MAPPING_PHONE = 'phone'
export const TIME_TYPE = 'time_type'
export const SUB_ACCOUNT_TAG = 'sub_account_tag'
export const TIME_TYPE_OPTION = 'time_type_option'
export const EXTRA_REQUIREMENTS = 'extra_requirements'
export const CUSTOM_REIMBURSEMENT = 'custom_reimbursement'
export const ANOTHER_IDEA = 'another_idea'
export const UPDATE_BATCH_CHAT_BOOKING_ATTRS = 'UPDATE_BATCH_CHAT_BOOKING_ATTRS'
export const UPDATE_CURRENT_BATCH_CHAT_BOOKING = 'UPDATE_CURRENT_BATCH_CHAT_BOOKING'
export const BATCH_STATUS_CONFIRMED = 'confirmed'
export const BATCH_STATUS_UNCONFIRMED = 'unconfirmed'
export const BATCH_STATUS_ARRANGING = 'arranging'
export const BATCH_STATUS_INPROGRESS = 'in_progress'
export const BATCH_STATUS_PARTIAL = 'partial'
export const BATCH_STATUS_PARTIALLY_CONFIRMED = 'partially_confirmed'
export const BATCH_STATUS_COMPLETED = 'completed'
export const BATCH_STATUS_CANCELED = 'canceled'
export const BATCH_STATUS_DRAFT = 'draft'
export const BATCH_TEMPLATE_INVIDUAL = 'invidual'
export const BATCH_TEMPLATE_COMPANY = 'company'
export const BATCH_STATUS_PENDING = 'pending'
export const BATCH_STATUS_SAVED = 'favorited'
export const BATCH_STATUS_DRIVER_ON_THE_WAY = 'driver_on_the_way'
export const UPDATE_BOOKING = 'EZ_SHEET/UPDATE_BOOKING'
export const UPDATE_BATCH_MENU = 'UPDATE_BATCH_MENU'
export const UPDATE_BATCH_ATTRS = 'UPDATE_BATCH_ATTRS'
export const UPDATE_BATCHES = 'UPDATE_BATCHES'

export const ADD_FLASH_BATCHES = 'add'
export const REMOVE_FLASH_BATCHES = 'remove'

export const UPCOMING = 'upcoming'
export const HISTORY = 'history'
export const DRAFT = 'draft'
export const LOADING = 'loading'
export const GRID_VIEW = 'grid_view'
export const MAP_VIEW = 'map_view'
export const CHAT_VIEW = 'chat_view'
export const STEP_UPLOAD_TAB_OUR_TEMPLATE = 'OurTemplate'
export const STEP_UPLOAD_TAB_USER_TEMPLATE = 'UserTemplate'
export const STEP_UPLOAD_TAB_ADVANCED = 'AdvancedTemplate'
export const SUPPORTED_DATETIME_FORMATS = ['dddd, mmmm d, yyyy h:mm AM/PM', 'dddd, mmmm dd, yyyy h:mm:ss AM/PM', 'dddd, mmmm dd, yyyy h:mm AM/PM', 'MDYYYY LT', 'M/D/YYYY LT', 'M/D/YYYY LTS', 'M/D/YYYY H:mm', 'M/D/YYYY H:mm:ss', 'm/d/yyyy h:mm AM/PM', 'mmddyyyy h:mm AM/PM']
export const UPDATE_ACTIVE_TRACKING_BOOKING = 'UPDATE_ACTIVE_TRACKING_BOOKING'
export const UPDATE_SINGLE_CONTACT = 'UPDATE_SINGLE_CONTACT'
export const BOOKING_NAME_MAX_LENGTH = '120'

export const MAX_LENGTH_ALPHABET = '26'
export const UPDATE_SELECTED_BOOKING = 'UPDATE_SELECTED_BOOKING'
export const EZ_COLUMNS = {
  ExpandContent: 'ExpandContent',
  Consolidate: 'Consolidate',
  ShipmentType: 'ShipmentType',
  BookingName: 'BookingName',
  JobOrderNumber: 'JobOrderNumber',
  SubAccountTag: 'SubAccountTag',
  VehicleType: 'VehicleType',
  TimeType: 'TimeType',
  ExtraRequirement: 'ExtraRequirement',
  BookingNote: 'BookingNote',
  PickupDateTime: 'PickupDateTime',
  LocationAddress: 'LocationAddress',
  CompletionEstimate: 'CompletionEstimate',
  CashPayor: 'CashPayor',
  LocationContactName: 'LocationContactName',
  LocationContactPhone: 'LocationContactPhone',
  LocationNote: 'LocationNote',
  RoundTripDiscount: 'RoundTripDiscount',
  LocationNeedPOD: 'LocationNeedPOD',
  LocationPODNote: 'LocationPODNote',
  LocationCODPOD: 'LocationCODPOD',
  LocationNeedCOD: 'LocationNeedCOD',
  LocationCODAmount: 'LocationCODAmount',
  LocationCODNote: 'LocationCODNote',
  Badge: 'Badge',
  ReimbursementToll: 'ReimbursementToll',
  ReimbursementParking: 'ReimbursementParking',
  ReimbursementWaiting: 'ReimbursementWaiting',
  OtherReimbursements: 'OtherReimbursements',
  FavoriteDriver: 'FavoriteDriver',
  SaveBooking: 'SaveBooking',
  RequireSignatures: 'RequireSignatures',
  PhotosAndFiles: 'PhotosAndFiles',
}
export const CREATED_FROM = {
  new_booking: 'new_booking',
  new_batchez: 'new_batchez',
  draft_booking: 'draft_booking',
  draft_batchez: 'draft_batchez',
  saved_booking: 'saved_booking',
  saved_batchez: 'saved_batchez',
  batchez_again: 'batchez_again',
}
// for add from saved booking
export const CREATED_BY_WEB_BATCH = 'web_batch'
export const MAX_HEIGHT_VEHICLE_ICON = 54
export const ADD_FROM_SAVED_BOOKING = 'saved_booking'

// for add from draft booking
export const ADD_FROM_DRAFT_BOOKING = 'draft_booking'

// Px size
export const PX_50 = 50
export const PX_500 = 500

export const MAX_LOCATIONS_NON_LOGIN_USER = 15
export const STEP_MAPPING = {
  multiple_1: 1,
  multiple_2: 2,
  multiple_3: 3,
  batch_4: 4,
  batch_5: 5,
  batch_6: 6,
  batch_7: 7
}
