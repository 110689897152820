import apiClient from 'services/axiosApp'

const ContactsAPI = {
  getList: (companyID, contactType, page) => {
    const queryParam = {
      params: {
        contact_type: contactType,
        company_id: companyID === 0 ? undefined : companyID,
        page
      },
    }
    return apiClient.get('/api/v3/contacts', queryParam)
  },
  getListBySearch: (query) => {
    const queryParam = {
      params: {
        page: 1,
        per_page: 10,
        ...query,
      },
    }
    return apiClient.get('/api/v3/contacts/search', queryParam)
  },
  create: (contact) => apiClient.post('/api/v3/contacts', contact),
  delete: (contactID) => apiClient.delete(`/api/v3/contacts/${contactID}`),
  update: (contact) => apiClient.put(`/api/v3/contacts/${contact.id}`, contact),
  search: async ({ authenticationToken, searchText, companyID, contactType, only, callback }) => {
    const res = await apiClient.get('/api/v3/contacts/search', {
      params: {
        contact_type: contactType,
        company_id: companyID === 0 ? undefined : companyID,
        search_text: searchText,
        only: only,
      },
    })
    return callback(res)
  },
  get: ({ id, only = [] }) => apiClient.get(`/api/v3/contacts/${id}`, {
    params: {
      id, 'only': only 
    }
  }),
  deleteMultiple: (payload) => apiClient.delete(`/api/v3/contacts/delete_contacts`, {
    data: payload
  }),
  copyContact: (payload) => apiClient.post(`/api/v3/contacts/copy`, payload),
  downloadTemplate: ()=> apiClient.get('/api/v3/contacts/download_template', {
    responseType: 'blob'
  }),
  uploadFileContact: (formData) => apiClient.post('/api/v3/contacts/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export default ContactsAPI
