import areaApi from 'api/Area'
import { redirectToSelectArea, getParamFromURL, removeListParamOnURL } from 'utils/booking/common'
import { cancelReasonsActionsCreator } from 'store/toolkit/cancelReasons/cancelReasons.reducer'
import { currentAreaActionsCreator } from 'store/toolkit/currentArea/currentArea.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import PATHS from 'routers/paths'
import * as areaActionCreators from 'store/actions/common/areaActionCreators'
import * as utilsCommon from 'utils/booking/common'

export const getActionReasons = (
  {
    scope, customerType, companyID, callback
  }
) => (dispatch, getState) => {
  const areaID = getState().extraInfos.id
  const bookingId = getState().booking.id
  const params = {
    id: areaID,
    scope,
    company_id: companyID > 0 ? companyID : '',
    customer_type: customerType
  }
  if (bookingId) params.booking_id = bookingId
  areaApi.actionReasons(areaID, params, (response) => {
    Promise.resolve(
      dispatch(cancelReasonsActionsCreator.areaUpdateCancelReasons(response?.data?.data))
    ).then(() => {
      callback(response?.data?.data, response?.data?.error)
    })
  })
}

export const actionReasonByArea = (
  {
    scope, areaID, customerType, companyID, callback
  }
) => (dispatch) => {
  const params = {
    id: areaID,
    scope,
    company_id: companyID > 0 ? companyID : '',
    customer_type: customerType
  }
  areaApi.actionReasons(areaID, params, (err, response) => {
    Promise.resolve(
      dispatch(cancelReasonsActionsCreator.areaUpdateCancelReasons(response.data.data))
    ).then(() => {
      callback()
    })
  })

}


export const getCurrentAreaCustomer = (countryCode) => async (dispatch) => {
  const currentAreaIdCustomer = await areaApi.getCurrentArea({
    ...(countryCode && { country_code: countryCode }),
    only: ['id']
  })
  if (currentAreaIdCustomer) {
    removeListParamOnURL(['area_id'])
    dispatch(getAreaInfo(currentAreaIdCustomer))
  } else {
    if(window.location.pathname !== PATHS.SELECT_AREA) {
      redirectToSelectArea()
    }
  }
}

let countCallApi = 0
export const getAreaInfo = areaId => async (dispatch) => {
  const dataServiceArea = await areaApi.getServiceArea(areaId)
  if(dataServiceArea.status === 500 && countCallApi < 5) {
    countCallApi++
    const countryCode = getParamFromURL('country_code')
    return dispatch(getCurrentAreaCustomer(countryCode))
  }
  const { object: objectArea, dropdown_areas: dropdownAreas, languages } = dataServiceArea

  dispatch(currentAreaActionsCreator.setCurrentArea({
    ...objectArea,
    supported_zone: objectArea?.settings?.supported_zone || []
  }))

  // set area_id after run getCustomerSettings
  if (objectArea?.id) {
    delete objectArea.id
  }
  dispatch(extraInfosActionsCreator.updateExtraInfos({
    ...objectArea,
    country_language: objectArea?.language || 'en',
    listLanguage: languages,
    dropdownAreas
  }))
}

export const getAreaSetting = (authenticationToken, currentCustomer, finalAreaId) => dispatch => {
  if (!finalAreaId) {
    const countryCode = utilsCommon.getParamFromURL('country_code')
    dispatch(areaActionCreators.getCurrentAreaCustomer(countryCode))
  }
  if (authenticationToken && !currentCustomer.id) return
  if (finalAreaId) {
    dispatch(areaActionCreators.getAreaInfo(finalAreaId))
    // sync area_id for session FTL
    areaApi.setNewCurrentAreaSession(finalAreaId)
  }
}
