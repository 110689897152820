import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as bookingsActionCreators from 'store/actions/multiple_bookings/bookingsActionCreators'
import * as bookingCardActionCreators from 'store/actions/booking_cards/bookingCardsActionCreators'
import SubAccountAPI from 'api/subAccountTag'
import BookingSubAccountTagAPI from 'api/bookings/subAccountTag'
import { Utils } from 'utils/Utils'
import { SEARCH_ICON } from 'constants/imageConstants'
import CustomSelect from '../../common/custom_select/CustomSelect'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    bookAgainDetails: state.bookAgainDetails,
    subAccountTagPicked: state.subAccountTagPicked,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    bookingsActions: bindActionCreators(bookingsActionCreators, dispatch),
    bookingCardActions: bindActionCreators(bookingCardActionCreators, dispatch),
  }
}

class ModalSubAccount extends Component {
  constructor() {
    super()
    this.state = {
      isSearch: false,
      listTag: [],
    }
    this.toggleInput = this.toggleInput.bind(this)
    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.onSortTagList = this.onSortTagList.bind(this)
    this.onSearchTag = this.onSearchTag.bind(this)
    this.emitChangeDebounced = _.debounce(this.onSearchTag, 300)
    this.LIST_SORT_SUB_ACCOUNT_TAG = [
      { name: I18n.t('webapp.default'), value: 'position' },
      { name: I18n.t('webapp.a_z'), value: 'name' },
      { name: I18n.t('webapp.recently_use'), value: 'last_used_at' },
    ]
  }

  componentDidMount() {
    this.fetchSubAccount()
  }

  onChangeSubAccount(item) {
    const {
      booking,
      bookingActions,
      bookingsActions,
      bookingCardActions,
      bookAgainDetails,
      currentCustomer,
      isFromBookingCard,
      isFromBookingDetail,
      closeModal
    } = this.props
    // New Booking case
    if (!_.isEmpty(bookAgainDetails)) {
      const subAccountTag = {
        ...bookAgainDetails.sub_account_tag,
        sub_account_id: item.id,
        sub_account_name: item.name,
      }
      bookingActions.updateBookAgainDetails({ sub_account_tag: subAccountTag })
    }

    if (!isFromBookingCard && !isFromBookingDetail && _.isEmpty(bookAgainDetails)) {
      const data = {
        sub_account_id: item.id,
        sub_account_name: item.name,
      }
      if (booking && booking.id) {
        bookingsActions.addSubAccountMultiple(booking.id, data)
      } else {
        bookingActions.addSubAccount(data)
      }
    }

    if (isFromBookingCard) {
      BookingSubAccountTagAPI.update(
        currentCustomer.authentication_token, booking.id, item.id,
        (response) => {
          if (response.status === 200) {
            bookingCardActions.addSubAccountCardBooking(booking.id, item)
          } else {
            toastr.error(response.data.error)
          }
        }
      )
    }

    if (isFromBookingDetail) {
      const data = {
        sub_account_id: item.id,
        sub_account_name: item.name
      }
      BookingSubAccountTagAPI.update(
        currentCustomer.authentication_token, booking.id, item.id,
        (response) => {
          if (response.status === 200) {
            bookingActions.addSubAccount(data)
          } else {
            toastr.error(response.data.error)
          }
        }
      )
    }

    closeModal()
    const element = document.querySelector('.sub-account-tooltip')
    if (element && !isFromBookingCard && !isFromBookingDetail) {
      element.scrollIntoView()
    }
  }

  onCloseModal() {
    const {
      closeModal,
      booking,
      bookingActions,
      bookingsActions,
      bookingCardActions,
      isFromBookingCard,
      isFromBookingDetail
    } = this.props

    if (booking) {
      if (booking.isCheckSubAccount && (!booking.sub_account_tag || !booking.sub_account_tag.sub_account_id)) {
        if (booking.id) {
          if (isFromBookingCard) {
            bookingCardActions.updateSubAccountCheckBoxCardBooking(booking.id, false)
          } else if (isFromBookingDetail) {
            bookingActions.updateSubAccountCheckBox(false)
          } else {
            // For multiple
            bookingsActions.updateSubAccountCheckBoxMultiple(booking.id, false)
          }
        } else {
        // For single
          bookingActions.updateSubAccountCheckBox(false)
        }
      }
    }
    closeModal()
  }

  onSortTagList(value) {
    const params = {
      sort_by: value,
      sort_dir: value === 'last_used_at' ? 'desc' : 'asc'
    }
    this.fetchSubAccount(params)
  }

  onSearchTag(params) {
    this.fetchSubAccount(params)
  }

  handleChangeInput(e) {
    const { value } = e.target
    const params = {
      search_text: value
    }
    this.emitChangeDebounced(params)
  }

  toggleInput(value) {
    this.setState({
      isSearch: value
    }, () => {
      if (!value) {
        this.fetchSubAccount()
      }
    })
  }

  fetchSubAccount(params = {}) {
    const { currentCustomer } = this.props
    let newParam = { ...params }
    if (currentCustomer.current_company_id) {
      newParam = { ...newParam, company_id: currentCustomer.current_company_id }
    }
    SubAccountAPI.getSubAccountTagList(
      currentCustomer.authentication_token,
      newParam,
      (res) => {
        if (res?.data?.data) {
          this.setState({
            listTag: res.data.data,
          })
        }
      }
    )
  }

  renderSubAccountTagList() {
    const { listTag } = this.state
    const {
      booking: {
        sub_account_tag: subAccountTag
      },
      bookAgainDetails,
      subAccountTagPicked,
    } = this.props

    const hasSubAccountTag = subAccountTag && subAccountTag.sub_account_id
    const hasSubAccountTagInBookAgain = bookAgainDetails
      && bookAgainDetails.sub_account_tag
      && bookAgainDetails.sub_account_tag.sub_account_id

    const content = listTag && listTag.map(item => (
      <li
        key={item.id}
        className={(hasSubAccountTag || hasSubAccountTagInBookAgain) === item.id === item.id ? 'active' : ''}
      >
        <div className="Overlay-RadioBox min-height45 dlvr-overlay-radio-box pt10 pb10">
          <span className="sa-name-text">
            {item.name}
          </span>
          <input
            type="radio"
            name="sub_account_choice"
            id={item.id}
            onChange={() => this.onChangeSubAccount(item)}
            checked={(subAccountTagPicked.sub_account_id
              ? subAccountTagPicked.sub_account_id
              : hasSubAccountTag || hasSubAccountTagInBookAgain) === item.id
            }
          />
          <label className="White-text">
            <i className="b material-icons Icon">
              fiber_manual_record
            </i>
          </label>
        </div>
      </li>
    ))

    return (
      <ul className="sub-account-ul vertical-scroll">
        {content}
      </ul>
    )
  }

  renderFilterFunction() {
    const { isSearch } = this.state
    return (
      <div className="sub-account-filter">
        {isSearch ? (
          <div className="Input" style={{ height: '28px' }}>
            <i className="b material-icons Icon gray ml10">
              <img src={SEARCH_ICON} />
            </i>
            <input
              onChange={this.handleChangeInput}
              placeholder={I18n.t('webapp.search')}
              autoFocus
            />
            <i
              onClick={() => this.toggleInput(false)}
              className="material-icons Icon mr6"
              style={{ fontSize: 12 }}
            >
              close
            </i>
          </div>
        ) : (
          <div className="flex flex-end-justify">
            <CustomSelect itemList={this.LIST_SORT_SUB_ACCOUNT_TAG} onChange={this.onSortTagList} />
            <button
              className="search-icon flex-shrink0 flex-center"
              onClick={() => this.toggleInput(true)}
              type="button"
            >
              <img src={SEARCH_ICON} />
            </button>
          </div>
        )}
      </div>
    )
  }

  render() {
    const {
      booking,
    } = this.props

    return (
      <div
        className="visible vertical-scroll Modal fixed Booking-Modal"
        style={booking && Utils.modifyPopupMultiple(false, booking.id)}
      >
        <div
          className="z-index-2 dlvr-normal-booking"
          style={{ width: 330 }}
        >
          <div className="Box">
            <div
              className="Box-Content"
              style={{ padding: '0 10px 10px' }}
            >
              <div className="Normal">
                <b className="White-text larger-font Modal-ChooseAccount-Title pt15 pb15 flex flex-space-between-justify">
                  {I18n.t('webapp.label.select_sub_account_tag')}
                  <i
                    className="material-icons cur-pointer flex-center"
                    onClick={() => this.onCloseModal()}
                    style={{ fontSize: 20 }}
                  >
                    close
                  </i>
                </b>
                <div className="Modal-ChooseSubAccount-Content Custom-Scroll-Group Radius-default">
                  {this.renderFilterFunction()}
                  {this.renderSubAccountTagList()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="close-modal-overlay fixed"
          onClick={() => this.onCloseModal()}
        />
      </div>
    )
  }
}

ModalSubAccount.propTypes = {
  currentCustomer: PropTypes.shape({}),
  bookingActions: PropTypes.func,
  bookingsActions: PropTypes.func,
  bookingCardActions: PropTypes.func,
  booking: PropTypes.shape({}),
  closeModal: PropTypes.func,
  isFromBookingCard: PropTypes.bool,
  isFromBookingDetail: PropTypes.bool,
  bookAgainDetails: PropTypes.shape({}),
}

ModalSubAccount.defaultProps = {
  currentCustomer: undefined,
  bookAgainDetails: undefined,
  bookingActions: undefined,
  bookingsActions: undefined,
  bookingCardActions: undefined,
  closeModal: () => undefined,
  booking: undefined,
  isFromBookingCard: false,
  isFromBookingDetail: false
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSubAccount)
