import { css } from 'styled-components'
import { styled } from '../STheme'
const IconWrap = styled.div<{ w?: string; h?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ w }) => w || 'auto'};
  width: ${({ h }) => h || 'auto'};
`
const Icon = styled.i<{ fs?: number; p?: number }>`
  ${({ p }) =>
    p &&
    css`
      padding: ${p ? `${p}px` : 'auto'};
    `}
  cursor: pointer;
  user-select: none;
  font-size: ${({ fs }) => fs || 18}px;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`
export { IconWrap, Icon }
