import BatchesAPI from 'api/batches'
import ContactsAPI from 'api/contacts'
import {
  BATCH_STATUS_UNCONFIRMED,
  STEP_MAPPING,
  CREATED_FROM,
  DRAFT,
  LOADING
} from 'constants/ezSpreadSheetConstants'
import * as utilBuildBookings from 'utils/ez_spread_sheet/buildBookings'
import _ from 'lodash'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import { batchTemplateActionsCreator } from 'store/toolkit/batchTemplate/batchTemplate.reducer'
import { pickupContactsActionsCreator } from 'store/toolkit/pickupContacts/pickupContacts.reducer'
import { pickupContactsMeFromLocationActionsCreator } from 'store/toolkit/pickupContactsMeFromLocation/pickupContactsMeFromLocation.reducer'
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer'

export const getBatchExtraInfos = ({
  batchType,
  editDraft = false,
  loadFromSaved = false
}) => (dispatch, getState) => {
  const accessToken = window.localStorage.getItem('access_token')
  const areaId = window.localStorage.getItem('areaId')
  const { currentCustomer } = getState()
  const query = {
    batch_type: batchType,
    area_id: areaId,
    edit_draft: editDraft,
    book_again: loadFromSaved,
    ...(currentCustomer.last_login_employ_id && { company_id: currentCustomer.last_login_employ_id })
  }

  BatchesAPI.getExtraInfos(accessToken, query).then((response) => {
    const { status, data } = response
    if (status === 200 && !_.isEmpty(data)) {
      const {
        pickup_contacts: pickupContacts,
        dynamic_texts: dynamicTexts,
        having_draft_batches: havingDraftBatches,
        having_saved_batches: havingSavedBatches,
        event
      } = data
      const extraInfosEzSheet = {
        ...data.object,
        ezSheetExtraInfo: {
          pickupContacts,
          dynamicTexts,
          havingDraftBatches,
          havingSavedBatches,
          event
        }
      }
      dispatch(extraInfosActionsCreator.updateExtraInfos(extraInfosEzSheet))
      dispatch(pickupContactsActionsCreator.updatePickupContacts(pickupContacts))
    }
  })
}

export const buildBookingSaved = batchData => (dispatch, getState) => {
  const {
    extraInfos,
    currentCustomer
  } = getState()
  let tempBookings = []
  const { bookings, batch_bookings: tempData, status } = batchData
  const tempStatus = status !== DRAFT ? LOADING : status
  const options = { added_from: CREATED_FROM.batchez_again }
  if (!_.isEmpty(tempData)) {
    tempBookings = _.map(tempData, booking => ({
      ...booking,
      use_credit: null
    }))
  } else if (!_.isEmpty(bookings)) {
    tempBookings = _.map(bookings, booking => (
      {
        ...booking,
        booking_extra_requirements: [],
      }
    ))
  }
  const batchBookings = utilBuildBookings.buildBatchEzInfo(
    tempBookings, extraInfos, currentCustomer, options
  )

  dispatch(batchActionsCreator.updateBatch({
    attrs: {
      bookings: batchBookings,
      step: 'batch_4',
      status: tempStatus
    }
  }))
}

export const getBatchInfos = ({ batchId, loadFromSaved }) => async (dispatch) => {
  const authenToken = window.localStorage.getItem('access_token')
  const query = {
    'include': ['batch_bookings', 'extra_requirements', 'locations', 'vehicle_type']
  }

  const { status, data } = await BatchesAPI.getBatchInfos({ authenToken, batchId, query })

  if (status === 200 && !_.isEmpty(data)) {
    const { object: batchRes } = data
    const stepToLoad = loadFromSaved ? STEP_MAPPING.batch_4 : STEP_MAPPING[batchRes.step]

    if (loadFromSaved) {
      dispatch(buildBookingSaved(batchRes))
    } else {
      dispatch(batchActionsCreator.updateBatch({
        attrs: {
          ...batchRes,
          status: BATCH_STATUS_UNCONFIRMED
        }
      }))
    }
    dispatch(currentStepActionsCreator.changeStep(stepToLoad))

    const teamplateRes = await BatchesAPI.getBatchTemplate({
      authenToken,
      templateId: batchRes.batch_template_id
    })

    if (teamplateRes.status === 200 && !_.isEmpty(teamplateRes.data)) {
      const { object: batchTemplate } = data
      dispatch(batchTemplateActionsCreator.updateBatchTemplate(batchTemplate))
    }
  }
}

export const getMultipleExtraInfos = areaId => async (dispatch) => {
  const accessToken = window.localStorage.getItem('access_token')

  const resMultiple = await BatchesAPI.getMultipleExtraInfos(areaId, accessToken)

  if (!_.isEmpty(resMultiple)) {
    const {
      pickup_contacts: pickupContacts,
      having_draft_batches: havingDraftBatches,
      having_saved_batches: havingSavedBatches,
      event
    } = resMultiple
    const extraInfosMultiple = {
      extraInfosMultiple: {
        pickupContacts,
        havingDraftBatches,
        havingSavedBatches,
        event
      }
    }
    dispatch(extraInfosActionsCreator.updateExtraInfos(extraInfosMultiple))
    dispatch(pickupContactsActionsCreator.updatePickupContacts(pickupContacts))
  }
}

export const getContact = () => async (dispatch, getState) => {
  const { currentCustomer } = getState()
  const queryContact = {
    ...(currentCustomer.last_login_employ_id && { company_id: currentCustomer.last_login_employ_id })
  }
  const { status, data } = await ContactsAPI.getList(queryContact)

  if (status === 200 && !_.isEmpty(data)) {
    dispatch(pickupContactsMeFromLocationActionsCreator.updatePickupContactsMeFromLocation({
      data: data.data,
      pagination: data.pagination
    }))
  }
}

export const updateExtraInfos = (data) => async (dispatch) => {
  dispatch(extraInfosActionsCreator.updateExtraInfos(data))
}
