import baseStyled, { ThemedStyledInterface } from 'styled-components'
export const theme = {
  colors: {
    dark: {
      1: '#000',
      2: '#333',
      3: '#444',
      4: '#282828',
    },
    green: {
      1: '#3fae29',
      2: '#0e730f',
      3: '#09670a',
    },
    red: {
      1: '#f04433',
    },
    yellow: {
      1: '#ffdb00',
    },
    white: {
      1: '#FFFFFF',
    },
    blue: {
      1: '#00b9e6',
    },
    gray: {
      1: '#f9f9f9',
      2: '#efefef',
      3: '#cecece',
      4: '#aaa',
      5: '#949494',
      7: '#6d6d6d',
      8: '#d4d4d4',
      9: '#a1a1a1',
      10: '#7c7c7c',
      11: '#f1f1f1',
    },
  },
  fonts: ['Roboto', 'Helvetica', 'sans-serif'],
  fontWeight: {
    400: 400,
    500: 500,
    700: 700,
  },
  fontSizes: {
    10: '10px',
    11: '11px',
    12: '12px',
    13: '13px',
    14: '14px',
    15: '15px',
    16: '16px',
    18: '18px',
    20: '20px',
    24: '24px',
    28: '28px',
    42: '42px',
  },
  radius: {
    3: '3px',
    4: '4px',
    5: '5px',
    6: '5px',
  },
  shadow: {
    1: '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
  zIndex: {
    1: 1,
    2: 2,
    3: 3,
  },
  breakpoints: {
    xl: 1920,
    lg: 1600,
    md: 1280,
    sm: 960,
    xs: 600,
    desktop: 1024,
    mobile: 768,
  },
}
export type Theme = typeof theme
export const styled = baseStyled as ThemedStyledInterface<Theme>
